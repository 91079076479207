:root {
  --yellow: rgb(255, 199, 9);
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
  --menuWidth: 250px;
}

* {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

body {
  background-color: var(--yellow);
}
